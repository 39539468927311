import ApiConfig from './ApiConfig'
import { ErrorMarkClient_V1 } from './generated/api.generated.v1'

import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTypeErrorMarks(context, type) {
      const api = new ErrorMarkClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getTypeErrorMarks(type)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPatientErrorMarks(context, patientId) {
      const api = new ErrorMarkClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientErrorMarks(patientId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postErrorMark(context, errorMarkDTO) {
      const api = new ErrorMarkClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postErrorMark(errorMarkDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}
