<template>
  <div id="severity-select">
    <v-combobox
      key="id"
      v-model="selectedSeverity"
      :items="severityList"
      item-text="display"
      :readonly="readonly"
      outlined
      label="Select Severity"
      clearable
      dense
      @change="selectSeverity(selectedSeverity)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import allergyService from '@/services/AllergyService'
import useAllergyService from '@/store/useAllergyService'

export default {
  props: {
    severity: {
      type: Object,
      default: () => ({display: "Unknown"}),
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) {
        store.unregisterModule(ALLERGY_APP)
      }
    })

    const { severityList, fetchSeverities } = useAllergyService()

    const selectedSeverity = ref(props.severity.display)

    const selectSeverity = () => {
      emit('severity-select', selectedSeverity)
    }

    return {
      severityList,
      selectedSeverity,
      fetchSeverities,

      required,

      selectSeverity,
    }
  },
  mounted() {
    this.fetchSeverities()
    this.$watch('severityList', () => {
      if (this.severity != null && this.severity !== undefined) {
        this.severityList.forEach(severity => {
          console.log(severity, this.severity)
          if ((severity.display === this.severity.display) || (severity.id === this.severity.id) || (severity.id === this.severity.Id)) {
            this.selectedSeverity = severity
          }
        })
    }
      this.selectSeverity()
    })
  },
}
</script>
