<template>
  <div id="symptom-select">
    <v-combobox
      v-model="selectedSymptom"
      :items="symptomList"
      item-text="display"
      outlined
      :readonly="readonly"
      label="Select Symptom"
      clearable
      multiple
      dense
      @change="selectSymptom(selectedSymptom)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import allergyService from '@/services/AllergyService'
import useAllergyService from '@/store/useAllergyService'

export default {
  props: {
    symptoms: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) {
        store.unregisterModule(ALLERGY_APP)
      }
    })

    const { symptomList, fetchSymptoms } = useAllergyService()

    const splitSymptoms = () => {
      //console.log('Symptom Split', props.symptoms)
    }

    const selectedSymptom = ref(props.symptoms)

    const selectSymptom = () => {
      const symptomNames = []
      selectedSymptom.value.forEach(symptom => {
        if (symptom.display === undefined) {
          symptomNames.push(symptom)
        } else {
          symptomNames.push(symptom.display)
        }
      })

      emit('symptom-select', symptomNames)
    }

    return {
      symptomList,
      selectedSymptom,
      fetchSymptoms,

      required,

      selectSymptom,
      splitSymptoms,
    }
  },
  mounted() {
    this.fetchSymptoms()
  },
}
</script>
