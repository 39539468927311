import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useErrorMarkList() {
  const errorMarkList = ref([])

  const tableColumns = [
    { text: 'JacketID', value: 'patient.jacketId' },
    { text: 'Patient', value: 'patient.displayName' },
    { text: 'TYPE', value: 'type' },
    { text: 'REASON', value: 'reason' },
    { text: 'NOTE', value: 'note' },
    { text: 'ENTERED', value: 'modifiedDate' },
    { text: 'ENTERED BY', value: 'modifiedBy' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    groupBy: ['patient.jacketId'],
    sortDesc: [true],
    itemsPerPage: 5
  })
  const loading = ref(false)
  const searchQuery = ref('')
  const typeList = ['PatientForm', 'PatientVitals', 'PatientProblem', 'PatientScript', 'PatientTreatment', 'PatientImmunization',  'PatientAllergy', 'PatientTuberculosis','PatientDocument', 'PatientLab', 'PatientImage']
  const selectedType = ref(null)

  // Fetch Error Marks

  const fetchSiteErrorMarks = () => {
    console.log(selectedType.value)
    loading.value = true
    store
      .dispatch('app-error/fetchTypeErrorMarks', selectedType.value)
      .then(response => {
        errorMarkList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([selectedType], () => {
    loading.value = true
    fetchSiteErrorMarks()
  })

  const fetchPatientErrorMarks = patientId => {
    loading.value = true
    store
      .dispatch('app-error/fetchPatientErrorMarks', patientId)
      .then(response => {
        errorMarkList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchPatientErrorMarks()
  })

  const postErrorMark = errorMarkDTO => {
    store
      .dispatch('app-error/postErrorMark', errorMarkDTO)
      .then(response => {
        console.log(response)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    postErrorMark()
  })

  return {
    typeList,
    selectedType,
    options,
    loading,
    searchQuery,

    errorMarkList,
    tableColumns,

    postErrorMark,
    fetchSiteErrorMarks,
    fetchPatientErrorMarks,
  }
}
