import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"25%"},model:{value:(_vm.isErrorMarkDialogActive),callback:function ($$v) {_vm.isErrorMarkDialogActive=$$v},expression:"isErrorMarkDialogActive"}},[_c(VForm,{ref:"errorMarkForm"},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Error Mark Confirmation")])]),_c(VCardText,[_c('span',[_vm._v(" Are you sure you want to mark item as error?")])]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VRadioGroup,{attrs:{"row":"","rules":[_vm.required]},model:{value:(_vm.errorReason),callback:function ($$v) {_vm.errorReason=$$v},expression:"errorReason"}},[_c(VRadio,{attrs:{"label":"Incorrect Patient","value":"Incorrect Patient","default":""}}),_c(VRadio,{attrs:{"label":"Incorrect Entry - New Note Created","value":"Incorrect Entry - New Note Created"}})],1)],1)],1),_c(VRow,[_c(VCol,[_c(VTextarea,{attrs:{"dense":"","label":"Note","outlined":"","rules":[_vm.required]},model:{value:(_vm.errorNote),callback:function ($$v) {_vm.errorNote=$$v},expression:"errorNote"}})],1)],1)],1),_c(VCardActions,[_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.confirmErrorMark()}}},[_vm._v(" Confirm ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }