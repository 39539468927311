<template>
  <div>
    <div>
      <v-row justify="center">
        <v-dialog
          v-model="isAllergyFormActive"
          persistent
          max-width="50%"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Allergy Details</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="allergyForm"
                @submit="validate"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="patientAllergyDTO.patientId"
                      label="Patient ID"
                      hint="GUID"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <allergy-select
                      v-model="patientAllergyDTO.allergyDescriptionCode"
                      color="primary"
                      label="Allergy Description"
                      :allergy="patientAllergyDTO.allergyDescriptionCode"
                      :readonly="!isEdit"
                      :rules="[required]"
                      @allergy-select="updateAllergy"
                    >
                    </allergy-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <severity-select
                      v-model="patientAllergyDTO.allergySeverityId"
                      label="Allergy Severity"
                      hint="Mild"
                      :readonly="!isEdit"
                      :severity="patientAllergyDTO.allergySeverity"
                      :rules="[required]"
                      @severity-select="updateSeverity"
                    ></severity-select>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <symptom-select
                      v-model="patientAllergyDTO.symptoms"
                      :symptoms="patientAllergyDTO.symptoms"
                      :readonly="!isEdit"
                      label="Allergy Symptoms"
                      hint="Swelling|Dry Eyes"
                      @symptom-select="updateSymptoms"
                    ></symptom-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-menu
                      ref="menuref"
                      v-model="menuID"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :readonly="!isEdit"
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-text-field
                          v-model="patientAllergyDTO.identificationDate"
                          :prepend-icon="icons.mdiCalendar"
                          label="Identification Date"
                          hint="YYYY-MM-DD"
                          v-bind="attrs"
                          v-on="on"
                          :readonly="!isEdit"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="patientAllergyDTO.identificationDate"
                        :show-current="true"
                        color="primary"
                        :readonly="!isEdit"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-menu
                      ref="menuref"
                      v-model="menuLO"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :readonly="!isEdit"
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                        :readonly="!isEdit"
                      >
                        <v-text-field
                          v-model="patientAllergyDTO.lastOccurrence"
                          :prepend-icon="icons.mdiCalendar"
                          :readonly="!isEdit"
                          label="last Occurrence Date"
                          hint="YYYY-MM-DD"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="patientAllergyDTO.lastOccurrence"
                        :show-current="true"
                        :readonly="!isEdit"
                        color="primary"
                        reactive
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <status-select
                      v-model="patientAllergyDTO.allergyStatusId"
                      label="Allergy Status"
                      hint="Confirmed"
                      :readonly="!isEdit"
                      :rules="[required]"
                      :status="patientAllergyDTO.allergyStatus"
                      @status-select="updateStatus"
                    ></status-select>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-switch
                    v-if="isEdit"
                    v-model="patientAllergyDTO.isActive"
                    color="primary"
                    :readonly="!isEdit"
                    >
                    <template v-slot:label>
                      {{patientAllergyDTO.isActive ? "Active" : "Inactive"}}
                    </template>
                  </v-switch>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      color="error"
                      type="reset"
                      text
                      @click="closeForm()"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      v-if="isEdit"
                      color="primary"
                      text
                      @click="submitForm()"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import allergyService from '@/services/AllergyService'
import useAllergyList from '@/store/useAllergyService'
import AllergySelect from '@/components/patient/AllergySelect.vue'
import SeveritySelect from '@/components/problem/SeveritySelect.vue'
import StatusSelect from '@/components/patient/StatusSelect.vue'
import SymptomSelect from '@/components/patient/SymptomSelect.vue'
import CommonList from '@/store/CommonService'

export default {
  components: {
    AllergySelect,
    SeveritySelect,
    StatusSelect,
    SymptomSelect,
  },
  model: {
    prop: 'isAllergyFormActive',
    event: 'update:is-allergy-form-active',
  },
  props: {
    isAllergyFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    patientAllergyDTO: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    console.log(props.patientAllergyDTO)

    const allergyForm = ref(null)
    const { postPatientAllergy } = useAllergyList()
    const menuID = ref(false)
    const menuLO = ref(false)
    const menuref = ref(null)

    const validate = patientAllergyDTO => {
      if (allergyForm.value.validate(patientAllergyDTO)) {
        return true
      }

      return false
    }

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update:is-allergy-form-active', false)
    }

    const { formatDate } = CommonList()

    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) store.unregisterModule(ALLERGY_APP)
    })

    return {
      allergyForm,
      validate,
      closeForm,
      refreshParent,

      postPatientAllergy,

      required,
      menuID,
      menuLO,
      menuref,
      save,

      formatDate,

      icons: {
        mdiClose,
      },
    }
  },
  created(){
    if(this.patientAllergyDTO.id == undefined) {
      this.patientAllergyDTO.identificationDate = this.patientAllergyDTO.identificationDate == undefined ? this.formatDate(new Date()) : this.formatDate(this.patientAllergyDTO.identificationDate)
      this.patientAllergyDTO.lastOccurrence = this.patientAllergyDTO.lastOccurrence == undefined ? this.formatDate(new Date()) : this.formatDate(this.patientAllergyDTO.lastOccurrence)
      this.patientAllergyDTO.isActive = true
    }
  },
  methods: {
    submitForm() {
      if (this.validate(this.patientAllergyDTO)) {
        //console.log('posting', this.patientAllergyDTO)
        this.postPatientAllergy(this.patientAllergyDTO)

        this.closeForm()
        this.refreshParent()
      } else {
        console.log('Not Valid')
      }
    },
    updateAllergy(allergy) {
      this.patientAllergyDTO.allergyDescriptionCode = allergy.value.valueDescription
      //console.log('new allergy', allergy.value)
      //console.log('allergy update', this.patientAllergyDTO)
    },
    updateSeverity(severity) {
      this.patientAllergyDTO.allergySeverityId = severity.value.id
      //console.log('new severity', severity)
      //console.log('severity update', this.patientAllergyDTO)
    },
    updateStatus(status) {
      this.patientAllergyDTO.allergyStatusId = status.value.id
      //console.log('new status', status.value.id)
      //console.log('status update', this.patientAllergyDTO)
    },
    updateSymptoms(symptoms) {
      this.patientAllergyDTO.symptoms = symptoms
      console.log('new symptom', symptoms)
      console.log('symptom update', this.patientAllergyDTO)
    },
  },
}
</script>
