<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="isErrorMarkDialogActive"
        persistent
        max-width="25%"
      >
        <v-form
          ref="errorMarkForm"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Error Mark Confirmation</span>
            </v-card-title>
            <v-card-text>
              <span> Are you sure you want to mark item as error?</span>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <v-radio-group
                    v-model="errorReason"
                    row
                    :rules="[required]"
                  >
                    <v-radio
                      label="Incorrect Patient"
                      value="Incorrect Patient"
                      default
                    ></v-radio>
                    <v-radio
                      label="Incorrect Entry - New Note Created"
                      value="Incorrect Entry - New Note Created"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="errorNote"
                    dense
                    label="Note"
                    outlined
                    :rules="[required]"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="error darken-1"
                    text
                    @click="closeDialog()"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="primary darken-1"
                    text
                    @click="confirmErrorMark()"
                  >
                    Confirm
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  model: {
    prop: 'isErrorMarkDialogActive',
    event: 'update:is-error-mark-dialog-active',
  },
  props: {
    isErrorMarkDialogActive: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    selectedItem: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeDialog = () => {
      emit('update:is-error-mark-dialog-active', false)
    }

    const errorMarkForm = ref(null)

    const validate = entry => {
      if (errorMarkForm.value.validate(entry)) {
        return true
      }

      return false
    }

    const confirmErrorMark = () => {
      const errorMarkDTO = {
        type: props.type,
        reason: errorReason.value,
        note: errorNote.value,
        entityJSON: props.selectedItem,
        entityId: props.selectedItem.id,
        IsActive: true,
      }

      if(validate(errorMarkDTO)){
        emit('error-mark-confirmation', errorMarkDTO)
        closeDialog()
        refreshParent()
      }
    }

    const errorReason = ref('Incorrect Patient')
    const errorNote = ref()

    return {
      closeDialog,
      confirmErrorMark,
      refreshParent,

      validate,
      required,

      errorMarkForm,
      errorReason,
      errorNote,
    }
  },
}
</script>
