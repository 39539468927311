<template>
  <div id="patient-allergy-list">
    <!-- dialog -->

    <v-dialog
      v-model="isAllergyFormActive"
    >
      <allergy-add-edit
        v-model="isAllergyFormActive"
        :is-edit="isEdit"
        :patient-allergy-d-t-o="selectedItem"
        @add-allergy="isAllergyFormActive"
        @refetch-data="fetchData"
      ></allergy-add-edit>
    </v-dialog>

    <div
      v-if="selectedItem != null"
    >
      <error-mark-confirmation
        v-if="isErrorMarkDialogActive"
        v-model="isErrorMarkDialogActive"
        :selected-item="selectedItem"
        type="PatientAllergy"
        @error-mark-confirmation="submitErrorMark"
        @refetch-data="fetchData"
      ></error-mark-confirmation>
    </div>

    <v-card>
      <v-card-title> Patient Allergies</v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          max-width="20px"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="allergy-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="isAllergyFormActive"
            color="primary"
            class="mb-4 me-3"
            @click="isAllergyFormActive = !isAllergyFormActive, isEdit = true, selectedItem = {patientId: patientId}"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Allergy</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedAllergy"
        :headers="patientAllergyColumns"
        :items="allergyList"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
        group-by="isActive"
        :group-desc="true"
      >

      <template v-slot:group.header="{ group, headers, toggle, isOpen }">
            <td :colspan="headers.length">
                <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                    <v-icon v-if="isOpen"> {{icons.mdiMinus}}</v-icon>
                    <v-icon v-else>{{icons.mdiPlus}}</v-icon>
                </v-btn>
                Active - {{ group }}
            </td>
        </template>

        <!-- actions -->
        <template #[`item.isActive`]="{item}">
          {{ readStatus(item.isActive) }}
        </template>
        <template #[`item.identificationDate`]="{item}">
          {{ formatDate(item.identificationDate) }}
        </template>
        <template #[`item.lastOccurrence`]="{item}">
          {{ formatDate(item.lastOccurrence) }}
        </template>
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isAllergyFormActive = !isAllergyFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isAllergyFormActive = !isAllergyFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isErrorMarkDialogActive = !isErrorMarkDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRemoveOutline }}
                  </v-icon>
                  <span>Mark Error</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="updateStatus(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span v-if="item.isActive">Disable</span>
                  <span v-else>Activate</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiFileRemoveOutline,
  mdiMinus,
} from '@mdi/js'

import allergyService from '@/services/AllergyService'
import useAllergyList from '@/store/useAllergyService'
import AllergyAddEdit from '@/views/allergy/PatientAllergyAddEdit.vue'
import CommonList from '@/store/CommonService'
import errorMarkService from '@/services/ErrorMarkService'
import useErrorMarkService from '@/store/useErrorMarkService'
import ErrorMarkConfirmation from '@/components/validation/ErrorMarkConfirmation.vue'

export default {
  components: { AllergyAddEdit, ErrorMarkConfirmation },
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
    }
  },
  setup() {
    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) {
        store.unregisterModule(ALLERGY_APP)
      }
    })

    const {
      allergyList,
      patientAllergyColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedAllergy,

      fetchPatientAllergies,
      postPatientAllergy,
      deletePatientAllergy,
    } = useAllergyList()

    const { formatDate, readStatus } = CommonList()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isAllergyFormActive = ref(false)
    const isErrorMarkDialogActive = ref(false)

    const ERROR_APP = 'app-error'

    // Register module
    if (!store.hasModule(ERROR_APP)) {
      store.registerModule(ERROR_APP, errorMarkService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ERROR_APP)) {
        store.unregisterModule(ERROR_APP)
      }
    })

    const {
      postErrorMark
    } = useErrorMarkService()

    return {
      allergyList,
      patientAllergyColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedAllergy,
      isAllergyFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,
      isErrorMarkDialogActive,
      postErrorMark,

      deletePatientAllergy,
      fetchPatientAllergies,
      postPatientAllergy,
      formatDate,
      readStatus,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiMinus,
        mdiFileRemoveOutline,
      },
    }
  },
  mounted() {
    if (this.patientData != null || this.patientData !== undefined) {
      this.fetchPatientAllergies(this.patientData.id)
    }
    else if (this.patientId != null && this.patientId !== undefined) {
      this.fetchPatientAllergies(this.patientId)
    }

    this.$watch('allergyList', () => {
      Object.keys(this.$refs).forEach(k => {
        if (k == "false") {
          this.$refs[k].$el.click()
        }
      })
    })
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      //console.log(this.selectedItem)
    },
    submitDelete(allergyDTO) {
      this.deletePatientAllergy(allergyDTO.id)
    },
    submitErrorMark(errorMarkDTO) {
      errorMarkDTO.patientId = this.patientData.id
      console.log('Posting Error Mark', errorMarkDTO)
      this.postErrorMark(errorMarkDTO)
    },
    updateStatus(item) {
      this.selectedItem = item
      this.selectedItem.isActive = !item.isActive
      console.log(this.selectedItem)
      this.postPatientAllergy(this.selectedItem)
    },
    fetchData() {
      this.fetchPatientAllergies(this.patientId)
    },
    log(msg) {
      //console.log(msg)
    },
  },
}
</script>
