<template>
  <div id="status-select">
    <v-combobox
      key="id"
      v-model="selectedStatus"
      :items="statusList"
      item-text="display"
      :readonly="readonly"
      outlined
      label="Select Status"
      clearable
      dense
      @change="selectStatus(selectedStatus)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import allergyService from '@/services/AllergyService'
import useAllergyService from '@/store/useAllergyService'

export default {
  props: {
    status: {
      type: Object,
      default: () => ({display: "Unconfirmed"}),
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) {
        store.unregisterModule(ALLERGY_APP)
      }
    })

    const { statusList, fetchStatuses } = useAllergyService()

    const selectedStatus = ref(props.status.display)

    const selectStatus = () => {
      emit('status-select', selectedStatus)
    }

    return {
      statusList,
      selectedStatus,
      fetchStatuses,

      required,

      selectStatus,
    }
  },
  mounted() {
    this.fetchStatuses()
    this.$watch('statusList', () => {
      if (this.status != null && this.status !== undefined) {
        this.statusList.forEach(status => {
          console.log(status, this.status)
          if ((status.display === this.status.display) || (status.id === this.status.id) || (status.id === this.status.Id)) {
            this.selectedStatus = status
            console.log('Status Selected', this.selectedStatus)
          }
        })
      }
      this.selectStatus()
    })
  },
}
</script>
