<template>
  <div id="allergy-select">
    <v-combobox
      v-model="selectedAllergy"
      :items="allergyList"
      item-text="typeDesc"
      outlined
      label="Select Allergy"
      clearable
      :readonly="readonly"
      dense
      @click="log(allergy)"
      @change="selectAllergy(selectedAllergy)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import allergyService from '@/services/AllergyService'
import useAllergyService from '@/store/useAllergyService'

export default {
  props: {
    allergy: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) {
        store.unregisterModule(ALLERGY_APP)
      }
    })

    const { allergyList, fetchAllergies } = useAllergyService()

    const selectedAllergy = ref(props.allergy)

    const log = msg => {
      //console.log(msg)
    }

    const selectAllergy = () => {
      emit('allergy-select', selectedAllergy)
    }

    return {
      allergyList,
      selectedAllergy,
      fetchAllergies,

      required,
      log,

      selectAllergy,
    }
  },
  mounted() {
    this.fetchAllergies()
  },
}
</script>
